import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import * as PropTypes from "prop-types";

export default class AfterLoginActions extends React.Component {
    render() {
        return <Row>
            <Col></Col>
            <Col md={6}>
                <h3>Azioni</h3>
                <Row>
                    <Col>
                        <Button onClick={this.props.onOpenNewReview}>
                            Modifica Recensione
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={this.props.onOpenAllReview}>
                            Nuova Recensione
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={this.props.onOpenNewTags}>
                            Modifica Tag/Generi
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Button onClick={this.props.onOpenModifyAuthors}>
                            Autori
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={this.props.onOpenModifiableSection}>
                            Sezioni Modificabili
                        </Button>
                    </Col>
                    <Col>

                    </Col>
                </Row>
            </Col>
            <Col></Col>
        </Row>;
    }
}

AfterLoginActions.propTypes = {
    onOpenNewReview: PropTypes.func,
    onOpenAllReview: PropTypes.func,
    onOpenNewTags: PropTypes.func,
    onOpenModifiableSection: PropTypes.func,
    onOpenModifyAuthors: PropTypes.func
};
