import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState, useCallback } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import HeroHeader from "../components/HeroHeader";
import MovieCard from "../components/MovieCardV2";
import NavbarTitlebox from "../components/NavbarTitlebox";
import Spinner from "../components/Spinner";
import WebsiteNavbar from "../components/WebsiteNavbar";
import { API_URL } from "../utils/config";

export default function Search() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [showModal, setShowModal] = useState(location.search === "");

  const [movies, setMovies] = useState([]);
  const [tags, setTags] = useState([]);
  const [state, setState] = useState({
    searchArguments: {
      movie_title: "",
      directors: "",
      actors: "",
      genre: "",
      year: "",
      score: "",
    },
    tags: [],
  });

  const _buildSearchQuery = useCallback(() => {
    let baseUrl = API_URL + "movies/search?";
    baseUrl += `title=${escape(state.searchArguments.movie_title)}&`;
    baseUrl += `director=${escape(state.searchArguments.directors)}&`;
    baseUrl += `actor=${escape(state.searchArguments.actors)}&`;
    baseUrl += `tags=${escape(state.searchArguments.genre == "genere" ? "" : state.searchArguments.genre)}&`;
    baseUrl += `year=${escape(state.searchArguments.year ?? "")}&`;
    baseUrl += `score=${escape(state.searchArguments.score ?? "")}`;
    return baseUrl;
  }, []);

  const _setSearchArgumentsFor = (label) => {
    return ({ target }) => {
      const searchArguments = state.searchArguments;
      searchArguments[label] = target.value;
      setState({ ...state, searchArguments });
    };
  };

  const _search = useCallback(
    async (withQuery) => {
      const query = withQuery ?? _buildSearchQuery();
      let results = await fetch(query);
      results = await results.json();
      setMovies(results.result);
      setShowModal(false);
    },
    [_buildSearchQuery],
  );

  useEffect(() => {
    async function fetchTags() {
      let fetchedTags = await fetch(API_URL + "movies/tags");
      fetchedTags = (await fetchedTags.json()).tags[0].tags;
      setTags(fetchedTags);
    }

    async function submitOnEnter (event) {
      if (event.key === "Enter") await _search()
    }


    if (location.search !== "") {
      _search(API_URL + "movies/search" + location.search);
    }


    fetchTags();
    document.addEventListener("keydown", submitOnEnter)

  }, [_search, location]);

  const _closeAll = () => {
    setShowModal(false);
  };

  return (
    <>
      <HeroHeader allowRouteToHome={true}>
        <NavbarTitlebox />
      </HeroHeader>
      <WebsiteNavbar />
      <Modal
        show={showModal}
        size={"md"}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cerca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className={"m-2"}>
              <Form.Label column sm="2">
                Titolo
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  placeholder="Titolo"
                  onChange={_setSearchArgumentsFor("movie_title")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={"m-2"}>
              <Form.Label column sm="2">
                Regista
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  placeholder="Regista"
                  onChange={_setSearchArgumentsFor("directors")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={"m-2"}>
              <Form.Label column sm="2">
                Attori
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  placeholder="Attori"
                  onChange={_setSearchArgumentsFor("actors")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={"m-2"}>
              <Form.Label column sm="2">
                Genere
              </Form.Label>
              {
                // Mettere il form di selezione dei tag
              }
              <Col sm="10">
                <Form.Select
                  defaultValue={"genere"}
                  onChange={(e) => {
                    setState({
                      searchArguments: {
                        ...state.searchArguments,
                        genre: e.target.selectedOptions[0].value,
                      },
                      tags: state.tags,
                    });
                  }}
                >
                  <option selected disabled value={"genere"}>
                    {" "}
                    Genere
                  </option>
                  {(state.tags ?? ["avventura"]).sort().map((v) => (
                    <option value={v}>{v}</option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={"m-2"}>
              <Form.Label column sm="2">
                Anno
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type={"number"}
                  placeholder="Anno"
                  onChange={_setSearchArgumentsFor("year")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className={"m-2"}>
              <Form.Label column sm="2">
                Voto
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type={"number"}
                  placeholder="Voto"
                  onChange={_setSearchArgumentsFor("score")}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type={"submit"}
            as={"input"}
            value={"Cerca"}
            onClick={() => _search()}
          />
        </Modal.Footer>
      </Modal>

      <Container>
        <Row>
          {(movies ?? []).map((movie, i) => {
            return <MovieCard movie={movie} key={i} />;
          })}
        </Row>
      </Container>
    </>
  );
}
