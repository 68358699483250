import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";

import Main from "./pages/Main";
import MainV2 from "./pages/MainV2";
import Login from "./pages/Login";
import Presentation from "./pages/Presentation";
import Search from "./pages/Search";
import Movie from "./pages/Movie";
const router = createHashRouter([
  {
    path: "/",
    element: <MainV2 />,
  },
  {
    path: "/presentation",
    element: <Presentation />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/movie/:id",
    element: <Movie />,
  },
]);

export default class App extends React.Component {
  render() {
    return <RouterProvider router={router} />;
  }
}
