import React from "react";
import {Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import * as PropTypes from "prop-types";

export default class ModifiableAuthorForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            author: props.authorToModify
        }
    }

    static emptyAuthor(){
        return {
            citations: "",
            description: "",
            name: ""
        }
    }

    _create = () => {
        let {author} = this.state
        author.description = author.description.replace(/(?:\r\n|\r|\n)/g, '<br/>');
        author.citations = author.citations.replace(/(?:\r\n|\r|\n)/g, "+++")
        this.props.onCreate(author)
    }

    _update = () => {
        let {author} = this.state
        author.description = author.description.replace(/(?:\r\n|\r|\n)/g, '<br/>');
        author.citations = author.citations.replace(/(?:\r\n|\r|\n)/g, "+++")
        this.props.onUpdate(author)
    }

    _setAuthorArgumentsFor =  (key) => {
        return ({target}) => {
            let newState = {...this.state.author}
            newState[key] = target.value
            this.setState({author: {...newState}})
        }
    }
    _renderTooltipWithMessage = (message) => {
        return (props) =>
            (
                <Tooltip id="button-tooltip" {...props}>
                    {message}
                </Tooltip>
            )
    }
    render() {
        return (
            <Modal
                onHide={this.props.onHide}
                size={"md"}
                show={this.props.show}
                onShow={() => this.setState({author: this.props.reviewerToModify})}
                backdrop={"static"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Autore
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Row}>
                        <Form.Label column md="3">
                            Nome
                        </Form.Label>
                        <Col md="9">
                            <Form.Control as="textarea" defaultValue={this.props.reviewerToModify?.name} onChange={this._setAuthorArgumentsFor("name")}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column md="3">
                            Descrizione
                        </Form.Label>
                        <Col md="9">
                            <Form.Control
                                as="textarea"
                                defaultValue={this.props.reviewerToModify?.description.replace(/<br\/>/g, "\n")}
                                onChange={this._setAuthorArgumentsFor("description")}
                            />
                        </Col>
                    </Form.Group>

                    <OverlayTrigger
                        placement="right"
                        delay={{show: 0, hide: 400}}
                        overlay={this._renderTooltipWithMessage("Citazioni separate da accapo.")}
                    >
                        <Form.Group as={Row}>
                            <Form.Label column md="3">
                                Citazioni preferite
                            </Form.Label>
                            <Col md="9">
                                <Form.Control
                                    as="textarea"
                                    defaultValue={this.props.reviewerToModify?.citations.replace(/\+\+\+/g, "\n")}
                                    onChange={this._setAuthorArgumentsFor("citations")}
                                />
                            </Col>
                        </Form.Group>
                    </OverlayTrigger>

                </Modal.Body>
                <Modal.Footer>

                    <Col style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button variant={"primary"}
                                onClick={this.props.type === "create" ? () => this._create() : () => this._update()}>
                            {this.props.type === "create" ? "Crea" : "Aggiorna"}
                        </Button>
                    </Col>

                </Modal.Footer>
            </Modal>
        )
    }


}

ModifiableAuthorForm.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.any,
    type: PropTypes.string,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    reviewerToModify: PropTypes.shape({})
};
