import React from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {API_URL} from "../utils/config";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

export default class ModifiableSectionForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sections: []
        }
    }

    async componentDidMount() {
        let res = await fetch(API_URL + "inputs/")
        res = await res.json()
        this.setState({sections: res})
    }

    _setArgumentsFor = (key) => {
        return (action) => {
            let target = action.target?.value ?? action
            let newState = {...this.state}
            newState[key] = target
            this.setState({...newState})
        }
    }
    _update = async () => {

        for (let [k, v] of Object.entries(this.state)) {
            if (k !== "sections") {
                try {
                    await fetch(API_URL + "inputs/", {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify(
                            {
                                token: this.props.token,
                                inputType: k,
                                inputText: v
                            }
                        )
                    })

                    // Go through the sections and change the updated value
                    let {
                        sections
                    } = this.state

                    for (let i = 0; i < sections.length; i++) {
                        if (sections[i].input_type === k)
                            sections[i].input_text = v
                    }
                    this.setState({sections})

                } catch (e) {
                    alert("Modifica non riuscita")
                }


            }
        }

        this.props.onHide()

    }

    render() {
        return (
            <Modal onHide={this.props.onHide} size={"md"} backdrop={"static"} show={this.props.shouldOpen}
                   onShow={this._syncPropsAndState}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Sezioni Modificabili
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        /*
                           List of changeable sections
                         */
                        this.state.sections.map(({id, input_type: inputType, input_text: inputText}) => {
                            return (
                                <div>
                                    <Form.Group as={Row}>
                                        <Row>
                                            <Col className={"text-center"}>
                                                <b>{inputType}</b>
                                            </Col>
                                        </Row>
                                        <Row>

                                        </Row>
                                        <Col>
                                            <div data-color-mode="light">
                                                <MDEditor
                                                    value={this.state.titlebox ?? inputText ?? ""}
                                                    onChange={this._setArgumentsFor(inputType)}
                                                    previewOptions={{
                                                        rehypePlugins: [[rehypeSanitize]],
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Form.Group>
                                </div>
                            )
                        })

                    }
                </Modal.Body>

                <Modal.Footer>
                    <Col style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button variant={"primary"}
                                onClick={() => this._update()}>
                            Salva
                        </Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        )
    }

}

