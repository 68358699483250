import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

import WebsiteHeader from "../components/WebsiteHeader";
import WebsiteNavbar from "../components/WebsiteNavbar";
import MovieCard from "../components/MovieCard";
import { API_URL } from "../utils/config";
import { cachedFetch } from "../utils/CachedFetch";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      movies: [],
      isInitialLoad: true,
      movieFromUrl: null,
      moviesCollection: null,
      showModal: false,
      openedMovieCard: null,
      moviesByGenre: {},
      searchArguments: {
        movie_title: "",
        directors: "",
        actors: "",
        genre: "",
        year: null,
      },
      isLoading: false,
    };
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener("popstate", () => {
      if (this.state.openedMovieCard != null)
        this.state.openedMovieCard.setClose();
    });
  }

  _movieCardFromUrl = null;

  _getRecentlyReviewedMovies = async () => {
    let res = await fetch(API_URL + "movies/recently_added");
    res = await res.json();
    this.setState({
      moviesByGenre: {
        ...this.state.moviesByGenre,
        "ultime recensioni": res.recently_added,
      },
    });
  };

  _getRecentMovies = async () => {
    let res = await fetch(API_URL + "movies/recent_movies");
    res = await res.json();
    this.setState({
      moviesByGenre: {
        ...this.state.moviesByGenre,
        "film recenti": res.recent_movies,
      },
    });
  };

  _getHomepageGenres = async () => {
    const response = await fetch(API_URL + "movies/homepage_genre");
    return await response.json();
  };

  _getMoviesByGenre = (genres) => {
    return genres.forEach((genre) => {
      cachedFetch(API_URL + `movies/homepage?genre=${genre}`).then((movies) => {
        let mbg = this.state.moviesByGenre;
        const genre = Object.keys(movies)[0];
        mbg[genre] = movies[genre];
        this.setState({ moviesByGenre: { ...mbg } });
      });
    });
  };

  async componentDidMount() {
    if (window.location.href.includes("id") && this.state.isInitialLoad) {
      const id = parseInt(window.location.href.split("id=")[1]);
      let res = await fetch(API_URL + "movies/movie?id=" + id);
      res = await res.json();
      this.setState({ movieFromUrl: res.movies[0], isInitialLoad: false });
      await this._movieCardFromUrl.setShow();
    }

    await this._getRecentMovies();
    await this._getRecentlyReviewedMovies();
    const genres = await this._getHomepageGenres();
    await this._getMoviesByGenre(genres.homepage_genre);

    this.setState({ isInitialLoad: false });
  }

  _showModal = async () => {
    this.setState({ isLoading: true });
    let tags = await fetch(API_URL + "movies/tags");
    tags = (await tags.json()).tags[0].tags;
    this.setState({ showModal: true, allTags: tags, isLoading: false });
  };

  render() {
    return (
      <>
        <Spinner isLoading={this.state.isLoading} />
        <WebsiteHeader />
        <WebsiteNavbar canSearch={true} />
        {!this.state.showSearch && (
          <Container className={"p-3"}>
            {Object.entries(this.state.moviesByGenre ?? {}).map(
              ([genre, moviesByGenre], i) => {
                return (
                  <Row key={i}>
                    <h3>{genre.capitalize()}</h3>
                    <div className="d-flex flex-row overflow-auto">
                      {moviesByGenre.map((m, i) => {
                        return (
                          <MovieCard
                            onOpen={(openedMovieCard) =>
                              this.setState({ openedMovieCard })
                            }
                            movie={m}
                            key={i}
                            showTagSearch={true}
                          />
                        );
                      })}
                    </div>
                  </Row>
                );
              },
            )}
          </Container>
        )}

        <MovieCard
          ref={(r) => (this._movieCardFromUrl = r)}
          movie={this.state.movieFromUrl}
          onOpen={(openedMovieCard) => this.setState({ openedMovieCard })}
          key={999}
          isSearch={true}
          showTagSearch={true}
        />
      </>
    );
  }
}
