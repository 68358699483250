import React from "react";
import {Button, Row, Col, Form, Modal, OverlayTrigger, Tooltip, ListGroup} from "react-bootstrap";
import Spinner from "../components/Spinner";
import LoginForm from './../components/LoginForm'
import AfterLoginActions from "../components/AfterLoginActions";
import {API_URL} from "../utils/config";
import ModifiableMovieForm from "../components/ModifiableMovieForm";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import ModifiableSectionForm from "../components/ModifiableSectionForm";
import ModifiableReviewerForm from "../components/ModifiableReviewerForm";
import * as PropTypes from "prop-types";
import DraggableList from "react-draggable-list";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {DragIndicator} from "@mui/icons-material";



class PreferredTagItem extends React.Component {
    render() {
        return <li
            className={"list-group-item d-flex justify-content-between align-items-center"}

        >
            <Col sm={"8"}>
                <span>{this.props.tag}</span>
            </Col>
            <Col sm={"4"} style={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <Button variant={"outline-danger"}
                        className={"p-1 ml-1 mr-1"}
                        onClick={this.props.onClick}><RemoveOutlinedIcon/></Button>
                <Button variant={"outline-primary"}
                        className={"p-1  ml-1 mr-1"}
                        onClick={this.props.onClick1}><AddOutlinedIcon/></Button>
            </Col>
        </li>;
    }
}

PreferredTagItem.propTypes = {
    provided: PropTypes.any,
    tag: PropTypes.any,
    onClick: PropTypes.func,
    onClick1: PropTypes.func
};



class TagComponent extends React.Component {
    render() {
        const { item, dragHandleProps, commonProps } = this.props;

        return <ListGroup.Item
          as={"li"}
          className="d-flex justify-content-between align-items-start"
          key={item.i}
        >
                <div style={{
                    cursor: "move",
                }} className={""} {...dragHandleProps} > <DragIndicatorIcon />
                </div>

                <span>{item.tag}</span>
                <Button variant={"outline-danger"} className={"p-1 ml-1 mr-1"}
                        onClick={() => {
                            console.log(this.props)
                            commonProps.onClick(item.tag)()
                        }}><RemoveOutlinedIcon/></Button>
        </ListGroup.Item>;
    }
}

TagComponent.propTypes = {
    tag: PropTypes.any,
    onClick: PropTypes.func
};
export default class Login extends React.Component {
    homepageTagsContainer = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            newReview: {
                features: {}
            },
            isLoading: false,
            shouldOpenNewReviewModal: false,
            shouldOpenAllReviewsModal: false,
            shouldOpenModifyTagsModal: false,
            shouldOpenModifiableSectionsModal: false,
            shouldOpenDisplayReviewersModal: false,
            shouldOpenModifyReviewerModal: false,
            movies: [],
            displayMovies: [],
            reviewers: [],
            displayReviewers: [],
            reviewerToModify: null,
            movieToModify: ModifiableMovieForm.emptyMovie(),
            reviewsToModify: [],
            reviews: [],
            validation: {},
            movieTags: [],
            homepageTags: [],
            newTagValue: "",
            originalMovieTags: [],
            originalHomepageTags: []
        }
    }

    async componentDidMount() {
        document.addEventListener("keydown", this._submitOnEnter)

        // fetch current tags
        let tags = await fetch(API_URL + "movies/tags")
        tags = (await tags.json()).tags[0].tags
        let homepage_tags = await fetch(API_URL + "movies/homepage_tags")
        homepage_tags = (await homepage_tags.json()).homepage_tags[0].genre

        this.setState({
            movieTags: tags,
            originalMovieTags: tags,
            homepageTags: homepage_tags,
            originalHomepageTags: homepage_tags
        })
    }

    _submitOnEnter = async (event) => {
        if (this.state.user?.token) return
        if (event.key === "Enter") await this._doLogin()
    }

    _setLoginArgumentsFor = (key) => {
        return ({target}) => {
            let newState = {...this.state}
            newState[key] = target.value
            this.setState({...newState})
        }
    }

    _doLogin = async () => {
        if (this.state.user?.token != null) return
        try {
            const email = this.state.email
            const password = this.state.password
            this.setState({isLoading: true})
            let res = await fetch(API_URL + "auth/login", {
                method: "POST", body: JSON.stringify({email, password}), headers: {"Content-Type": "application/json"},
            })
            res = await res.json()
            this.setState({user: res})
        } catch (err) {
            // Respond with 401 - unauthorized
        }
        this.setState({isLoading: false})


    }

    _b64 = (blob) => {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result);
        });
    }

    _compressImage = (file) => {
        const MAX_HEIGHT = 250;
        const MAX_WIDTH = MAX_HEIGHT * 0.7;
        const img = new Image();

        return new Promise((resolve, _) => {
            img.src = URL.createObjectURL(file);
            img.onerror = function () {
                URL.revokeObjectURL(this.src);
                // Handle the failure properly
            };

            img.onload = function () {
                URL.revokeObjectURL(this.src);
                const [newWidth, newHeight] = [MAX_WIDTH, MAX_HEIGHT]
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob((blob) => resolve(blob), "image/jpeg", 70);
            };
        })

    }

    _validateForms = (modalState) => {
        const invalidMovieAttrs = {valid: true}
        const {movie, reviews} = modalState

        Object.entries(movie).forEach(([key, value]) => {
            if (key == "starred") return
            if (value == "" || value == null) {
                invalidMovieAttrs[key] = true
                invalidMovieAttrs.valid = false
            }
        })

        this.setState({validation: invalidMovieAttrs})
        return invalidMovieAttrs.valid
    }

    _validateAndCreateReviews = async (modalState) => {

        if (!this._validateForms(modalState)) return

        const compressedImage = await this._compressImage(modalState.movie.image_src)
        const b64 = await this._b64(compressedImage)
        let newMovie = await fetch(API_URL + "movies/new", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(
                {
                    token: this.state.user.token,
                    movie: {
                        ...modalState.movie,
                        year: modalState.movie.year ?? (new Date().getFullYear()),
                        image_src: b64
                    }
                }
            )
        })
        newMovie = await newMovie.json()
        await this._createReviews(modalState.reviews, {...modalState.movie, id: newMovie.movie_id});
        this.setState({shouldOpenNewReviewModal: false})
        alert(`Link permanente alla recensione ${window.location.href.replace("/login", "")}/?id=${newMovie.movie_id}`)
    }

    _createReviews = async (reviews, movie) => {
        const results = await Promise.all(reviews.map(async (review) => {
            let newReview = await fetch(API_URL + "movies/reviews/new", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(
                    {
                        token: this.state.user.token,
                        review: {
                            movie_id: movie.id,
                            ...review
                        }
                    }
                )
            })
            return newReview.json()
        }))
    }

    _updateReview = async (modalState) => {
        if (!this._validateForms(modalState)) return
        try {
            let movie = await fetch(API_URL + "movies/update", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(
                    {
                        token: this.state.user.token,
                        movie: modalState.movie
                    }
                )
            })

            movie = await movie.json()

            const deletionResults = await Promise.all(modalState.reviews.map(async (review) => {
                let res = await fetch(API_URL + "movies/reviews/delete", {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(
                        {
                            token: this.state.user.token,
                            id: review.id
                        }
                    )
                })

                return res.json()
            }))

            await this._createReviews(modalState.reviews, modalState.movie)
        } catch (err) {
            console.log(err)
        }
        this.setState({shouldOpenModifyReviewsModal: false})
    }

    _openNewReviewModal = () => {
        this.setState({shouldOpenNewReviewModal: true})
    }

    _openModifyReviewersModal = async () => {
        this.setState({isLoading: true})
        let reviewers = await fetch(API_URL + "inputs/authors")
        reviewers = await reviewers.json()
        this.setState({reviewers: reviewers, displayReviewers: reviewers, shouldOpenDisplayReviewersModal: true, isLoading: false})
    }

    _openAllReviewsModal = async () => {
        this.setState({isLoading: true})
        let movies = await fetch(API_URL + "movies/")
        movies = await movies.json()

        this.setState({shouldOpenAllReviewsModal: true, movies: movies.movies, displayMovies: movies.movies, isLoading: false})
    }

    _openModifyReviewerModal = (author) => {
        return () => {
            this.setState({reviewerToModify: author, shouldOpenModifyReviewerModal: true})
        }
    }

    _openModifyReviewsModal = (movie) => {
        return async () => {

            let res = await fetch(API_URL + "movies/movie?id=" + movie.id)
            res = await res.json()
            this.setState({movieToModify: res.movies[0]})
            // do a fetch to retrieve the reviews and then
            // open the modal to modify them
            let reviews = await fetch(API_URL + "movies/reviews?id=" + movie.id)
            reviews = await reviews.json()
            this.setState({
                shouldOpenModifyReviewsModal: true,
                movieToModify: res.movies[0],
                reviewsToModify: reviews.reviews
            })
        }
    }

    _deleteReviewer = async (reviewer) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Sei sicuro di voler cancellare il recensore ${reviewer.name}? L'operazione è irreversibile.`)) {
            let res = await fetch(API_URL + "inputs/reviewer", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(
                    {
                        token: this.state.user.token,
                        reviewer: {...reviewer},
                        mode: "delete"
                    }
                )
            })
            console.log(await res.json())
            console.log(reviewer)
            let newReviewers = this.state.displayReviewers.filter(r => r.id !== reviewer.id)
            this.setState({
                reviewers: newReviewers,
                displayReviewers: newReviewers
            })
        }
    }

    _deleteMovie = async (movie) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Sei sicuro di voler cancellare ${movie.title} e le sue recensioni? L'operazione è irreversibile.`)) {
            let deletedMovie = await fetch(API_URL + "movies/delete", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(
                    {
                        token: this.state.user.token,
                        id: movie.id
                    }
                )
            })
            deletedMovie = await deletedMovie.json()

            let newDisplayMovies = this.state.displayMovies.filter(({id}) => id != deletedMovie.movie_id)

            this.setState({displayMovies: newDisplayMovies})
        }
    }

    _updateReviewer = async (reviewer) => {
        let res = await fetch(API_URL + "inputs/reviewer", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(
                {
                    token: this.state.user.token,
                    reviewer: {...reviewer}
                }
            )
        })

        res = await res.json()
        let newReviewers = [res[0], ...this.state.displayReviewers.filter(r => r.id !== reviewer.id)]

        this.setState({
            reviewers: newReviewers,
            displayReviewers: newReviewers,
            shouldOpenModifyReviewerModal: false
        })
    }

    _openNewTags = () => {
        this.setState({shouldOpenModifyTagsModal: true})
    }

    _openModifiableSection = () => {
        this.setState({shouldOpenModifiableSectionsModal: true})
    }

    _addToHomepageTags = (tag) => () => {
        const newHomepageTags = Array.from(new Set([...this.state.homepageTags, tag]))
        this.setState({homepageTags: newHomepageTags})
    }

    _removeHomepageTag = (tag) => () => {
        console.log("removing", tag)
        let newHomepageTags = this.state.homepageTags.filter(t => t !== tag)
        this.setState({homepageTags: newHomepageTags})
    }

    _removeFromMovieTags = (tag) => () => {
        let newMovieTags = this.state.movieTags.filter(t => t !== tag)
        this.setState({movieTags: newMovieTags})
    }

    _onNewGenreChanged = ({target}) => {
        this.setState({newTagValue: target.value})
    }

    _addNewTag = () => {
        this.setState({movieTags: [...this.state.movieTags, this.state.newTagValue], newTagValue: ""})
    }

    _applyModificationsToTags = async () => {
        // do post requests

        try {
            await fetch(API_URL + "movies/tags", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(
                    {
                        token: this.state.user.token,
                        tags: this.state.movieTags,
                    })
            })

            await fetch(API_URL + "movies/homepage_tags", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(
                    {
                        token: this.state.user.token,
                        tags: this.state.homepageTags,
                    })
            })

            this.setState({originalMovieTags: this.state.movieTags, originalHomepageTags: this.state.homepageTags})

        } catch (e) {
            console.log(e)
        }

        this.setState({shouldOpenModifyTagsModal: false})
    }

    render() {
        return (
            <div className="container-fluid">
                <Spinner
                  isLoading={this.state.isLoading}
                />
                {
                  (!this.state.user || !this.state.user.token) &&
                  <LoginForm
                    onEmailChanged={this._setLoginArgumentsFor("email")}
                    onPasswordChanged={this._setLoginArgumentsFor("password")}
                    onLogin={this._doLogin}
                    isLoading={this.state.isLoading}
                  />

                }
                {
                  this.state.user &&
                  this.state.user.token &&
                  <AfterLoginActions
                    onOpenNewReview={this._openAllReviewsModal}
                    onOpenAllReview={this._openNewReviewModal}
                    onOpenNewTags={this._openNewTags}
                    onOpenModifiableSection={this._openModifiableSection}
                    onOpenModifyAuthors={this._openModifyReviewersModal}
                  />
                }

                {/* Nuova Recensione */}
                <ModifiableMovieForm
                  onHide={() => this.setState({shouldOpenNewReviewModal: false})}
                  shouldOpen={this.state.shouldOpenNewReviewModal}
                  movie={ModifiableMovieForm.emptyMovie()}
                  reviews={[]}
                  validation={this.state.validation}
                  type={"create"}
                  tags={this.state.movieTags}
                  createReview={state => this._validateAndCreateReviews(state)}
                />

                {/* Modifica Recensione */}
                <Modal onHide={() => {
                    this.setState({shouldOpenAllReviewsModal: false})
                }} size={"md"} show={this.state.shouldOpenAllReviewsModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Tutte le recensioni
                        </Modal.Title>
                        <Form.Group className={"m-3"}>
                            <Col>
                                <Form.Control placeholder="Cerca" onChange={({target}) => {
                                    const filtered = this.state.movies.filter(movie => movie.title.toLowerCase().includes(target.value.toLowerCase()))
                                    this.setState({displayMovies: filtered})
                                }}/>
                            </Col>
                        </Form.Group>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.displayMovies.map((movie) => {
                            return (
                              <li className={"list-group-item d-flex justify-content-between align-items-center"}>
                                  <Col sm={"8"}>
                                      <span>{movie.title}</span>
                                  </Col>
                                  <Col sm={"4"} style={{display: "flex", justifyContent: "flex-end"}}>
                                      <Button variant={"outline-primary"} className={"m-1"}
                                              onClick={this._openModifyReviewsModal(movie)}><EditIcon/></Button>
                                      <Button variant={"outline-danger"} className={"m-1"}
                                              onClick={() => this._deleteMovie(movie)}><DeleteIcon/></Button>
                                  </Col>
                              </li>
                            )
                        })}
                    </Modal.Body>
                </Modal>

                <ModifiableMovieForm
                  onHide={() => this.setState({shouldOpenModifyReviewsModal: false})}
                  shouldOpen={this.state.shouldOpenModifyReviewsModal}
                  movie={this.state.movieToModify}
                  reviews={this.state.reviewsToModify}
                  type={"update"}
                  validation={this.state.validation}
                  tags={this.state.movieTags}
                  updateReview={state => this._updateReview(state)}
                />


                {/*Modifica Generi/Tag*/}
                <Modal
                  onHide={() => {
                      this.setState({
                          shouldOpenModifyTagsModal: false,
                          movieTags: this.state.originalMovieTags,
                          homepageTags: this.state.originalHomepageTags
                      })
                  }}
                  backdrop={"static"}
                  show={this.state.shouldOpenModifyTagsModal}
                  size={"md"}
                >

                    <Modal.Header closeButton>
                        <Modal.Title>
                            Modifica Tag/Generi
                        </Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        <h4>Tag sulla homepage</h4>
                        <Row ref={this.homepageTagsContainer}>
                            {/*<HomepageTagsComponent homepageTags={this.state.homepageTags} callbackfn={(tag, i) => {
                                return (
                                  <TagComponent key={i} tag={tag} onClick={this._removeHomepageTag(tag)}/>
                                )
                            }}/>*/}

                            <DraggableList
                                itemKey={"i"}
                                list={this.state.homepageTags.map((tag, i) => ({tag, i}))}
                                template={TagComponent}
                                onMoveEnd={(list) => this.setState({homepageTags: list.map(({tag}) => tag)})}
                                commonProps={{onClick: this._removeHomepageTag}}
                                container={() => this.homepageTagsContainer.current}
                            />


                        </Row>
                        <h4>Tutti i Tag</h4>
                        <Row>
                            <Col className={"p-3"}>

                                {
                                    this.state.movieTags.map((tag, idx) => {
                                        return (
                                          <PreferredTagItem
                                            tag={tag}
                                            onClick={this._removeFromMovieTags(tag)}
                                            onClick1={this._addToHomepageTags(tag)}/>
                                        )
                                    })
                                }
                                <ListGroup as={"ul"}>
                                    <Form.Group as={Row}>
                                        <Col sm="8">
                                            <Form.Control
                                              value={this.state.newTagValue}
                                              placeholder="Nuovo genere" onChange={this._onNewGenreChanged}/>
                                        </Col>
                                        <Col sm="4">
                                            <Button variant={"outline-primary"} onClick={this._addNewTag}
                                                    className={"p-1  ml-1 mr-1"}><AddOutlinedIcon/></Button>
                                        </Col>
                                    </Form.Group>
                                </ListGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button title={"Applica modifiche"} onClick={this._applyModificationsToTags}>Applica
                            Modifiche
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ModifiableSectionForm
                  shouldOpen={this.state.shouldOpenModifiableSectionsModal}
                  onHide={() => this.setState({shouldOpenModifiableSectionsModal: false})}
                  token={this.state.user?.token}
                />

                {/* Lista Recensori da modificare */}
                <Modal onHide={() => {
                    this.setState({shouldOpenDisplayReviewersModal: false})
                }} size={"md"} show={this.state.shouldOpenDisplayReviewersModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Recensori
                        </Modal.Title>
                        <Form.Group className={"m-3"}>
                            <Col>
                                <Form.Control placeholder="Cerca" onChange={({target}) => {
                                    const filtered = this.state.reviewers.filter(reviewer => reviewer.name.toLowerCase().includes(target.value.toLowerCase()))
                                    this.setState({displayReviewers: filtered})
                                }}/>
                            </Col>
                        </Form.Group>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.displayReviewers.map((reviewer) => {
                            return (
                              <li className={"list-group-item d-flex justify-content-between align-items-center"}>
                                  <Col sm={"8"}>
                                      <span>{reviewer.name}</span>
                                  </Col>
                                  <Col sm={"4"} style={{display: "flex", justifyContent: "flex-end"}}>
                                      <Button variant={"outline-primary"} className={"m-1"}
                                              onClick={this._openModifyReviewerModal(reviewer)}><EditIcon/></Button>
                                      <Button variant={"outline-danger"} className={"m-1"}
                                              onClick={() => this._deleteReviewer(reviewer)}><DeleteIcon/></Button>
                                  </Col>
                              </li>
                            )
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => {
                            this.setState({
                                shouldOpenModifyReviewerModal: true,
                                reviewerToModify: ModifiableReviewerForm.emptyAuthor()
                            })
                        }}>+</Button>
                    </Modal.Footer>
                </Modal>


                {/*Modifica autori*/}
                <ModifiableReviewerForm
                  onHide={() => {
                      this.setState({shouldOpenModifyReviewerModal: false})
                  }}
                  show={this.state.shouldOpenModifyReviewerModal}
                  reviewerToModify={this.state.reviewerToModify}
                  type={this.state.reviewerToModify?.id == null ? "create" : "modify"}
                  onUpdate={(reviewer) => this._updateReviewer(reviewer)}
                  onCreate={(reviewer) => this._updateReviewer(reviewer)}
                />

            </div>
        )
    }

}

