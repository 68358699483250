import Dexie from 'dexie'

const db = new Dexie("cache")
// Declare tables, IDs and indexes
db.version(3).stores({
    cache: '++id,url, response, validUntil'
});

export const cachedFetch = async (url, params, assumeJson = true) => {
    // delete stale entries
    let res = await fetch(url, params)
    res = await res.json()
    return res
    /*const deletedCount = await db.cache.where("validUntil").below(new Date()).delete()
    console.log(`Deleted ${deletedCount} entries`)

    const cachedResponse = await db.cache.where("validUntil").aboveOrEqual(new Date()).toArray()
    if(cachedResponse.length === 1) {
        console.log("cache hit!")
        return cachedResponse[0].response
    }

    let res = await fetch(url, params)
    if(assumeJson) res = await res.json()
    let threeDaysFromNow = new Date()
    threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 3)
    // store the response
    await db.cache.add({
        url,
        response: res,
        validUntil: threeDaysFromNow
    })
    return res*/
}

