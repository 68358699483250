import { API_URL } from "../utils/config";

const endpoints = {
  recent_movies: "movies/recent_movies",
  recently_added: "movies/recently_added",
  genres: "movies/homepage_genre",
};

const fetchWithoutParams = async (url) => {
  let res = await fetch(`${API_URL}${url}`);
  return await res.json();
};

const fetchMoviesByGenre = async (genre) => {
  const response = await fetch(`${API_URL}movies/homepage?genre=${genre}`);
  const content = await response.json();
  return content[genre];
};

export { endpoints, fetchWithoutParams, fetchMoviesByGenre };
