import { useLocation, useNavigate, useParams } from "react-router-dom";

import React, { Suspense, useState, useEffect } from "react";
import { Badge, Button, Modal, Row, Container, Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ReactMarkdown from "react-markdown";
import { NavLink } from "react-router-dom";
import rehypeSanitize from "rehype-sanitize";
import { API_URL } from "../utils/config";
import HeroHeader from "../components/HeroHeader";

import Dots from "../components/Dots";
const THEMOVIEDB_MOVIE_API_URL =
  "https://api.themoviedb.org/3/search/movie?include_adult=false&language=it-IT&page=1&query=";
const THEMOVIEDB_ACTOR_API_URL =
  "https://api.themoviedb.org/3/search/person?include_adult=false&language=en-US&page=1&query=";

export default function Movie() {
  const navigate = useNavigate();

  const tryLoadImageFromTitle = async () => {
    const title = movie.title;
    // use the moviedb api to fetch the movie details
    // then use the backdrop_path to fetch the image
    // then set the image as the hero header
    const res = await fetch(THEMOVIEDB_MOVIE_API_URL + title, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI5NmVhMGEyODIyOTc3NzU5NjhkYzkyZmMwODk4MTU0ZSIsInN1YiI6IjYyMGE0MGYxMWNhYzhjMDBiNzdlODZiMCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.gM8B0e1_NBQxLO_T-tcJeo0J9dH8pS6-wJkQ08q0mZY`,
      },
    });
    const movieData = await res.json();
    if (movieData.results.length == 0) return "";
    const firstResult = movieData.results[0];
    return `https://image.tmdb.org/t/p/original${firstResult.backdrop_path}`;
  };

  const loadMovieById = async (movieId) => {
    let res = await fetch(API_URL + `movies/movie?id=${movieId}`);
    res = await res.json();
    // TODO: if the movie is not found, redirect to 404!
    setMovie(res.movies[0]);
  };
  const onActorClicked = (actorName) => {
    navigate(`/search?actor=${actorName}`);
  };

  const { id } = useParams();
  const { state: forwardedMovie } = useLocation();
  const [movie, setMovie] = useState({});
  const [reviews, setReviews] = useState([]);
  const [actors, setActors] = useState([]);

  useEffect(() => {
    if (forwardedMovie) setMovie(forwardedMovie);
    else loadMovieById(id);
  }, [forwardedMovie, id]);

  useEffect(() => {
    const fetchActors = async (actorNames) => {
      const allActorsRequests = await Promise.all(
        actorNames.map((actorName) => {
          return fetch(THEMOVIEDB_ACTOR_API_URL + actorName, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI5NmVhMGEyODIyOTc3NzU5NjhkYzkyZmMwODk4MTU0ZSIsInN1YiI6IjYyMGE0MGYxMWNhYzhjMDBiNzdlODZiMCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.gM8B0e1_NBQxLO_T-tcJeo0J9dH8pS6-wJkQ08q0mZY`,
            },
          });
        }),
      );

      const allActorsData = await Promise.all(
        allActorsRequests.map((res) => res.json()),
      );

      const actors = allActorsData
        .map((actorData) => {
          if (actorData.results.length == 0) return undefined;
          return {
            name: actorData.results[0].name,
            imageUrl: `https://image.tmdb.org/t/p/original${actorData.results[0].profile_path}`,
          };
        })
        .filter((actor) => actor !== undefined);

      setActors(actors);
    };

    if (!movie) return;
    if (movie.actors && movie.actors.length !== 0) fetchActors(movie.actors);
  }, [movie]);

  useEffect(() => {
    const fetchReviews = async (movieId) => {
      let res = await fetch(API_URL + `movies/reviews?id=${movieId}`);
      res = await res.json();
      setReviews(res.reviews);
    };

    if (!reviews || reviews.length === 0) fetchReviews(movie.id);
  }, [movie, reviews]);

  return (
    <>
      {/*<HeroHeader imageLoader={tryLoadImageFromTitle} />*/}
      <Container className="pt-n5 mt-n5">
        <Row>
          <Col
            lg={4}
            md={4}
            sm={12}
            className="p-lg-5 d-flex justify-content-center align-items-center pt-5"
          >
            <Image
              src={movie.image_src}
              alt={movie.title + " poster"}
              style={{ width: 400 }}
            />
          </Col>
          <Col
            lg={8}
            md={8}
            sm={12}
            className="justify-content-center align-items-center p-lg-5 pt-5"
          >
            <h1>
              {movie.title +
                (movie.title == movie.original_title
                  ? ""
                  : " (" + (movie.original_title ?? "") + ")")}{" "}
            </h1>
            <h3 className="card-subtitle text-muted mb-lg-2">
              {movie.directors?.join(", ")}
            </h3>
            <h5 className="card-subtitle text-muted mb-lg-2">{movie.year}</h5>
            <div className={"mt-lg-5 mt-2"}>
              {movie.tags?.map((g, i) => (
                <Badge key={i} bg={"dark"} className={"m-1"}>
                  {g}
                </Badge>
              ))}
              <Badge className={"m-1"}>{movie.duration}'</Badge>
              <Badge bg={"success"} className={"m-1"}>
                {movie.year}
              </Badge>
              <Badge bg={"danger"} className={"m-1"}>
                {movie.state}
              </Badge>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <h2>Recensioni</h2>
          {reviews?.map((review, i) => {
            return (
              <Row key={i}>
                <Col md={12} lg={6}>
                  <div data-color-mode="light">
                    <ReactMarkdown
                      children={review.review_body ?? ""}
                    ></ReactMarkdown>
                  </div>
                  <p>
                    {" "}
                    Voto <b>{review.rating}</b>.
                  </p>
                  <p className={"fw-bold text-start"}>{review.author}</p>
                </Col>
                <Col md={12} lg={6}>
                  <ul className={"list-group p-2"}>
                    <li
                      className={
                        "list-group-item d-flex justify-content-between align-items-center"
                      }
                    >
                      <span>Età consigliata</span>
                      {review.age}+
                    </li>
                    <li
                      className={
                        "list-group-item d-flex justify-content-between align-items-center"
                      }
                    >
                      <span>Violenza</span>
                      {Dots(review.violence)}
                    </li>
                    <li
                      className={
                        "list-group-item d-flex justify-content-between align-items-center"
                      }
                    >
                      <span>Tensione</span>
                      {Dots(review.tension)}
                    </li>
                    <li
                      className={
                        "list-group-item d-flex justify-content-between align-items-center"
                      }
                    >
                      <span>Umorismo</span>
                      {Dots(review.humor)}
                    </li>
                    <li
                      className={
                        "list-group-item d-flex justify-content-between align-items-center"
                      }
                    >
                      <span>Dialoghi volgari</span>
                      {Dots(review.rudeness)}
                    </li>
                    <li
                      className={
                        "list-group-item d-flex justify-content-between align-items-center"
                      }
                    >
                      <span>Nudità/Sesso esplicito</span>
                      {Dots(review.sex)}
                    </li>
                    <li
                      className={
                        "list-group-item d-flex justify-content-between align-items-center"
                      }
                    >
                      <span>Comportamenti diseducativi</span>
                      {Dots(review.bad_behaviours)}
                    </li>
                    <li
                      className={
                        "list-group-item d-flex justify-content-between align-items-center"
                      }
                    >
                      <span>Possibilità discussione</span>
                      {Dots(review.discussion)}
                    </li>
                  </ul>
                </Col>
              </Row>
            );
          })}
        </Row>

        <Row className="mt-5">
          <h2>Attori</h2>
          <div className="d-flex flex-row overflow-auto">
            {actors?.map((actor, i) => {
              return (
                <Col
                  key={i}
                  lg={2}
                  md={3}
                  style={{ minWidth: 150 }}
                  onClick={() => onActorClicked(actor.name)}
                >
                  <Card key={i} className="m-2" style={{ cursor: "pointer" }}>
                    <Card.Img variant="top" src={actor.imageUrl} />
                    <Card.Body>
                      <Card.Title>{actor.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </div>
        </Row>

        <Row className="mt-5">
          {movie.tags?.map((tag, i) => {
            return (
              <NavLink to={`/search?tags=${tag}`} key={i}>
                Altri film con genere {tag}
              </NavLink>
            );
          })}
        </Row>
      </Container>
    </>
  );
}
