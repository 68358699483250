import React from "react";
import * as PropTypes from 'prop-types'
import {Form, Row, Col, Button, Modal} from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";


export default class ModifiableReviewForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            review: props.review
        }
    }

    static emptyReview = () => {
        return {
            review_body: "",
            author: "",
            age: null,
            bad_behaviours: 0,
            rudeness: 0,
            discussion: 0,
            sex: 0,
            tension: 0,
            humor: 0,
            violence: 0,
            rating: 0
        }
    }

    _setReviewProp = (reviewProp) => {
        return (action) => {
            let target = action.target?.value ?? action
            const newValue = {...this.state.review}
            newValue[reviewProp] = target
            this.setState({review: {...newValue}})
            this.props.setReviewProps(this.props.reviewIndex, newValue)
        }
    }

    forceStatePropsSync = () => {
        this.setState({review: this.props.review})
    }

    render() {
        const {idx: i} = this.props
        return (
            <>
                <Modal.Header>
                    <h4>Recensione #{this.props.reviewIndex + 1}</h4>
                    <Button variant={"outline-danger"} onClick={this.props.deleteReview}><DeleteIcon/></Button>
                </Modal.Header>
                <Form.Group as={Row} className={"mt-1"}>
                    <Row>
                        <Col md="12" className={"text-center"}>
                            <Form.Label column>
                                <b>Recensione</b>
                            </Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div data-color-mode="light">
                                <MDEditor
                                value={this.state.review.review_body ?? ""}
                                onChange={this._setReviewProp("review_body")}
                                previewOptions={{
                                    rehypePlugins: [[rehypeSanitize]],
                                }}
                            >

                            </MDEditor>
                            </div>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group as={Row} className={"mt-1"}>
                    <Form.Label column md="4">
                        Autore
                    </Form.Label>
                    <Col md="8">
                        <Form.Control type={"text"} placeholder="Autore" isInvalid={this.props.validation[i]?.author}
                                      value={this.state.review.author} onChange={this._setReviewProp("author")}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mt-1"}>
                    <Form.Label column md="4">
                        Età consigliata
                    </Form.Label>
                    <Col md="6" sm={"10"}>
                        <Form.Range
                            min={0}
                            max={21}
                            step={1}
                            value={this.state.review.age ?? 0}
                            onChange={this._setReviewProp("age")}
                            className={"mt-2"}
                        />
                    </Col>
                    <Col md="2" sm="2">
                        <Form.Control
                            readOnly defaultValue={this.state.review.age ?? 0} value={this.state.review.age ?? 0}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mt-1"}>
                    <Form.Label column md="4">
                        Voto
                    </Form.Label>
                    <Col md="6" sm="10">
                        <Form.Range
                            min={0}
                            max={10}
                            step={0.5}
                            value={this.state.review.rating ?? 0}
                            onChange={this._setReviewProp("rating")}
                            className={"mt-2"}
                        />
                    </Col>
                    <Col md="2" sm="2">
                        <Form.Control
                            readOnly defaultValue={this.state.review.rating ?? 0} value={this.state.review.rating ?? 0}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mt-1"}>
                    <Form.Label column md="4">
                        Comportamenti diseducativi
                    </Form.Label>
                    <Col md="6" sm="10">
                        <Form.Range
                            min={0}
                            max={4}
                            step={0.5}
                            value={this.state.review.bad_behaviours ?? 0}
                            onChange={this._setReviewProp("bad_behaviours")}
                            className={"mt-2"}
                        />
                    </Col>
                    <Col md="2" sm="2">
                        <Form.Control
                            readOnly defaultValue={this.state.review.bad_behaviours ?? 0}
                            value={this.state.review.bad_behaviours ?? 0}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mt-1"}>
                    <Form.Label column md="4">
                        Dialoghi volgari
                    </Form.Label>
                    <Col md="6" sm="10">
                        <Form.Range
                            min={0}
                            max={4}
                            step={0.5}
                            value={this.state.review.rudeness ?? 0}
                            onChange={this._setReviewProp("rudeness")}
                            className={"mt-2"}
                        />
                    </Col>
                    <Col md="2" sm="2">
                        <Form.Control
                            readOnly defaultValue={this.state.review.rudeness ?? 0}
                            value={this.state.review.rudeness ?? 0}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mt-1"}>
                    <Form.Label column md="4">
                        Possibilità discussione
                    </Form.Label>
                    <Col md="6" sm="10">
                        <Form.Range
                            min={0}
                            max={4}
                            step={0.5}
                            value={this.state.review.discussion ?? 0}
                            onChange={this._setReviewProp("discussion")}
                            className={"mt-2"}
                        />
                    </Col>
                    <Col md="2" sm="2">
                        <Form.Control
                            readOnly defaultValue={this.state.review.discussion ?? 0}
                            value={this.state.review.discussion ?? 0}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mt-1"}>
                    <Form.Label column md="4">
                        Nudità/Sesso esplicito
                    </Form.Label>
                    <Col md="6" sm="10">
                        <Form.Range
                            min={0}
                            max={4}
                            step={0.5}
                            value={this.state.review.sex ?? 0}
                            onChange={this._setReviewProp("sex")}
                            className={"mt-2"}
                        />
                    </Col>
                    <Col md="2" sm="2">
                        <Form.Control
                            readOnly defaultValue={this.state.review.sex ?? 0} value={this.state.review.sex ?? 0}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mt-1"}>
                    <Form.Label column md="4">
                        Tensione
                    </Form.Label>
                    <Col md="6" sm="10">
                        <Form.Range
                            min={0}
                            max={4}
                            step={0.5}
                            value={this.state.review.tension ?? 0}
                            onChange={this._setReviewProp("tension")}
                            className={"mt-2"}
                        />
                    </Col>
                    <Col md="2" sm="2">
                        <Form.Control
                            readOnly defaultValue={this.state.review.tension ?? 0}
                            value={this.state.review.tension ?? 0}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mt-1"}>
                    <Form.Label column md="4">
                        Umorismo
                    </Form.Label>
                    <Col md="6" sm="10">
                        <Form.Range
                            min={0}
                            max={4}
                            step={0.5}
                            value={this.state.review.humor ?? 0}
                            onChange={this._setReviewProp("humor")}
                            className={"mt-2"}
                        />
                    </Col>
                    <Col md="2" sm="2">
                        <Form.Control
                            readOnly defaultValue={this.state.review.humor ?? 0} value={this.state.review.humor ?? 0}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mt-1"}>
                    <Form.Label column md="4">
                        Violenza
                    </Form.Label>
                    <Col md="6" sm="10">
                        <Form.Range
                            min={0}
                            max={4}
                            step={0.5}
                            value={this.state.review.violence ?? 0}
                            onChange={this._setReviewProp("violence")}
                            className={"mt-2"}
                        />
                    </Col>
                    <Col md="2" sm="2">
                        <Form.Control
                            readOnly defaultValue={this.state.review.violence ?? 0}
                            value={this.state.review.violence ?? 0}
                        />
                    </Col>
                </Form.Group>
                <hr/>
            </>
        )
    }

}

ModifiableReviewForm.propTypes = {
    review: PropTypes.object,
    reviewIndex: PropTypes.number,
    deleteReview: PropTypes.func,
    setReviewProps: PropTypes.func
}
