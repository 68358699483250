import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { API_URL } from "../utils/config";

export default function NavbarTitlebox() {
  const [title, setTitle] = useState("");
  useEffect(() => {
    const fetchTitle = async () => {
      let res = await fetch(API_URL + "inputs?inputType=titlebox");
      res = await res.json();
      setTitle(res.input_text);
    };
    fetchTitle();
  }, []);
  return <ReactMarkdown children={title} />;
}
