import React from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
export default function WebsiteNavbar(props) {
  const isPresentation = window.location.href.includes("presentation");
  const isSearch = window.location.href.includes("search");
  const isHome = !(isSearch || isPresentation);

  return (
    <Navbar bg="white" expand="sm" sticky={"top"}>
      <Container>
        <Navbar.Brand href=""></Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-center">
          <Nav.Link className={isHome ? "border" : ""}>
            <NavLink to={"/"}>Film</NavLink>
          </Nav.Link>
          <Nav.Link className={isPresentation ? "border" : ""}>
            <NavLink to={"/presentation"}>Chi siamo</NavLink>
          </Nav.Link>
          {!isSearch && (
            <Nav.Link className={isSearch ? "border" : ""}>
              <NavLink to="/search">Cerca</NavLink>
            </Nav.Link>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
