import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import "../css/HeroHeader.css";
const DEFAULT_HERO_HEADER_IMAGE = require("../assets/hero_header3.jpg");

export default function HeroHeader({
  imagePath,
  imageLoader,
  allowRouteToHome,
  children,
}) {
  const [image, setImage] = useState(undefined);

  useEffect(() => {
    const loadImage = async (loader) => {
      const loadedImagePath = await loader();
      setImage(loadedImagePath);
    };

    if (imagePath) setImage(imagePath);
    else if (imageLoader) loadImage(imageLoader);
    else setImage(DEFAULT_HERO_HEADER_IMAGE);
  });

  return (
    <div className="blurBottom text-center">
      {allowRouteToHome ? (
        <Link to={"/"}>
          <img
            className="d-block img-fluid mx-auto mb-4"
            src={image}
            alt="hero header"
          />
        </Link>
      ) : (
        <img
          className="d-block img-fluid mb-4b mx-auto"
          src={image}
          alt="hero header"
        />
      )}
      {children}
    </div>
  );
}
