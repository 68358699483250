import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";

export default function MovieCardV2({ movie }) {
  const navigate = useNavigate();

  const goToMoviePage = () => {
    console.log(movie);
    navigate("/movie/" + movie.id, { state: movie });
  };

  return (
    <Col lg={2} md={3} style={{ minWidth: 300 }}>
      <Card
        className={"m-2"}
        onClick={goToMoviePage}
        style={{ minHeight: 300, cursor: "pointer" }}
      >
        <Card.Img variant={"top"} src={movie.image_src} loading="lazy" />
        <Card.Body>
          <Card.Title className={"text-truncate"}>{movie.title}</Card.Title>
          <Card.Text className={"text-truncate"}>
            Di {movie.directors.join(", ")}
          </Card.Text>
          <Card.Text>{movie.average_score}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
}
