import React from "react";
import {API_URL} from "../utils/config";
import {Link} from "react-router-dom"
import ReactMarkdown from "react-markdown";
export default class WebsiteHeader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            title: ""
        }
    }

    async componentDidMount() {
        let res = await fetch(API_URL + "inputs?inputType=titlebox")
        res = await res.json()
        this.setState({title: res.input_text})
    }

    render() {
        return (
            <div className="text-center">
                <Link to={"/"}>
                    <img className="d-block mx-auto mb-4 img-fluid"
                         src={require("../assets/hero_header3.jpg")}
                         alt="Logo cinema in famiglia"
                         href={"/"}
                    />
                </Link>
                <ReactMarkdown
                    children={this.state.title ?? ""}
                />
            </div>
        )
    }
}

