import React from "react";
import * as PropTypes from "prop-types";
import {Row, Col, Form, Button} from "react-bootstrap";
import {TailSpin} from "react-loader-spinner";

export default class LoginForm extends React.Component {
    render() {
        return <Row className={"justify-content-center mt-5"}>
            <Col md={"4"}> </Col>
            <Col md={"4"}>
                <Form className={"mt-1"}>
                    <h3>Login</h3>
                    <hr/>
                    <Form.Group as={Row}>
                        <Form.Label column md="3">
                            Email
                        </Form.Label>
                        <Col md="9">
                            <Form.Control placeholder="Email" onChange={this.props.onEmailChanged}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className={"mt-3"}>
                        <Form.Label column md="3">
                            Password
                        </Form.Label>
                        <Col md="9">
                            <Form.Control type={"password"} placeholder="Password" onChange={this.props.onPasswordChanged}/>
                        </Col>
                    </Form.Group>
                    <hr/>
                    <Row>
                        <Col></Col>
                        <Col></Col>
                        <Col md="3"><Button primary onClick={this.props.onLogin}> Login</Button></Col>

                    </Row>
                </Form>

            </Col>
            <Col md={"4"}></Col>

        </Row>;
    }
}

LoginForm.propTypes = {
    onChange: PropTypes.func,
    onChange1: PropTypes.func,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool
};
