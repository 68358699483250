import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import WebsiteHeader from "../components/WebsiteHeader";
import WebsiteNavbar from "../components/WebsiteNavbar";

import {
  endpoints as movieEndpoints,
  fetchWithoutParams as movieFetch,
} from "../api/movies";
import GenreRow from "../components/GenreRow";
import HeroHeader from "../components/HeroHeader";
import NavbarTitlebox from "../components/NavbarTitlebox";
import { AlignVerticalBottomRounded } from "@mui/icons-material";

export default function MainV2() {
  const [moviesPerCategory, setMoviesPerCategory] = useState([]);
  //const [openedMovieCard, setOpenedMovieCard] = useState(undefined);

  useEffect(() => {
    async function fetchAll() {
      const recentMovies = await movieFetch(movieEndpoints.recent_movies);
      const recentlyAdded = await movieFetch(movieEndpoints.recently_added);
      const genres = await movieFetch(movieEndpoints.genres);
      const moviesByGenres = genres.homepage_genre.map((g) => [g, undefined]); //await fetchMoviesByGenres(genres.homepage_genre);

      const allMovieReviews = [
        ["film recenti", recentMovies.recent_movies],
        ["ultime recensioni", recentlyAdded.recently_added],
        ...moviesByGenres,
      ];
      setMoviesPerCategory(allMovieReviews);
    }
    fetchAll();
  }, []);

  return (
    <div>
      <HeroHeader allowRouteToHome={true}>
        <NavbarTitlebox />
      </HeroHeader>
      <WebsiteNavbar />
      <Container className={"p-3"}>
        {(moviesPerCategory ?? []).map(([genre, moviesByGenre], i) => {
          return (
            <GenreRow
              index={i}
              genre={genre}
              moviesByGenre={moviesByGenre}
              forceLoad={i < 5}
            />
          );
        })}
      </Container>
    </div>
  );
}
