import React from "react";
import { Suspense } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Modal, Badge, Row, Button } from "react-bootstrap";
import { API_URL } from "../utils/config";
import Circle from "@mui/icons-material/Circle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import MDEditor from "@uiw/react-md-editor";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import { NavLink } from "react-router-dom";

function Dots(dotsCount) {
  const dc = parseInt(dotsCount);
  let hasHalf = parseFloat(dotsCount) - dc > 0;

  try {
    return (
      <span>
        {Array(dc)
          .fill(0)
          .map((_, i) => (
            <span key={i}>
              <Circle fontSize={"inherit"} />
            </span>
          ))}
        {hasHalf && (
          <span>
            <CircleTwoToneIcon fontSize={"inherit"} />
          </span>
        )}
        {Array(4 - dc - hasHalf)
          .fill(0)
          .map((_, i) => (
            <span key={i}>
              <CircleOutlined fontSize={"inherit"} />
            </span>
          ))}
      </span>
    );
  } catch (e) {
    return <span></span>;
  }
}

export default class MovieCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      reviews: [],
    };
  }

  _fetchReview = async () => {
    let res = await fetch(API_URL + `movies/reviews?id=${this.props.movie.id}`);
    res = await res.json();
    this.setState({ reviews: res.reviews });
  };

  _showModal = async () => {
    this.setState({ baseURL: window.location.href.split("?id")[0] });
    if (!window.location.href.includes("id")) {
      // eslint-disable-next-line no-restricted-globals
      const separator =
        window.location.href[window.location.href.length - 1] == "/" ? "" : "/";
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        {},
        null,
        `${window.location.href}${separator}?id=${this.props.movie.id}`,
      );
    }

    this.setState({ showModal: true });
    this.props.onOpen(this);
    await this._fetchReview();
  };

  setShow = async () => {
    console.log("Showing movie from url");
    if (this.props.movie == null) {
      return;
    }

    await this._showModal();
  };

  setClose = () => {
    this.setState({ showModal: false });
  };

  _goToMoviePage = () => {
    console.log(this.props);
  };

  render() {
    const { movie } = this.props;

    if (movie == null) return <></>;

    const style = {
      style: {
        minWidth: 200,
      },
    };

    return (
      <Suspense>
        <Col lg={2} md={3} style={{ minWidth: 300 }}>
          <Card
            className={"m-2"}
            onClick={this._goToMoviePage}
            style={{ minHeight: 300, cursor: "pointer" }}
          >
            <Card.Img variant={"top"} src={movie.image_src} />
            <Card.Body>
              <Card.Title className={"text-truncate"}>{movie.title}</Card.Title>
              <Card.Text className={"text-truncate"}>
                {" "}
                Di {movie.directors.join(", ")}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Modal
          show={this.state.showModal}
          size={"lg"}
          onHide={() => {
            this.setState({ showModal: false });
            // eslint-disable-next-line no-restricted-globals
            history.pushState({}, null, this.state.baseURL);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className={"modal-title"}>
              {" "}
              {movie.title +
                (movie.title == movie.original_title
                  ? ""
                  : " (" + (movie.original_title ?? "") + ")")}{" "}
            </Modal.Title>
          </Modal.Header>
          <div className={"m-3"}>
            <h6 className="card-subtitle text-muted mb-2">
              Di {movie.directors.join(", ")}.{" "}
              {`Con ${movie.actors.join(", ")}`}.
            </h6>
            {movie.tags.map((g, i) => (
              <Badge key={i} bg={"dark"} className={"m-1"}>
                {g}
              </Badge>
            ))}
            <Badge className={"m-1"}>{movie.duration}'</Badge>
            <Badge bg={"success"} className={"m-1"}>
              {movie.year}
            </Badge>
            <Badge bg={"danger"} className={"m-1"}>
              {movie.state}
            </Badge>
          </div>
          <Modal.Body>
            {this.state.reviews.map((review, i) => {
              return (
                <Row key={i}>
                  <Col md={12} lg={6}>
                    <div data-color-mode="light">
                      <ReactMarkdown
                        children={review.review_body ?? ""}
                      ></ReactMarkdown>
                    </div>
                    <p>
                      {" "}
                      Voto <b>{review.rating}</b>.
                    </p>
                    <p className={"fw-bold text-start"}>{review.author}</p>
                  </Col>
                  <Col md={12} lg={6}>
                    <ul className={"list-group p-2"}>
                      <li
                        className={
                          "list-group-item d-flex justify-content-between align-items-center"
                        }
                      >
                        <span>Età consigliata</span>
                        {review.age}+
                      </li>
                      <li
                        className={
                          "list-group-item d-flex justify-content-between align-items-center"
                        }
                      >
                        <span>Violenza</span>
                        {Dots(review.violence)}
                      </li>
                      <li
                        className={
                          "list-group-item d-flex justify-content-between align-items-center"
                        }
                      >
                        <span>Tensione</span>
                        {Dots(review.tension)}
                      </li>
                      <li
                        className={
                          "list-group-item d-flex justify-content-between align-items-center"
                        }
                      >
                        <span>Umorismo</span>
                        {Dots(review.humor)}
                      </li>
                      <li
                        className={
                          "list-group-item d-flex justify-content-between align-items-center"
                        }
                      >
                        <span>Dialoghi volgari</span>
                        {Dots(review.rudeness)}
                      </li>
                      <li
                        className={
                          "list-group-item d-flex justify-content-between align-items-center"
                        }
                      >
                        <span>Nudità/Sesso esplicito</span>
                        {Dots(review.sex)}
                      </li>
                      <li
                        className={
                          "list-group-item d-flex justify-content-between align-items-center"
                        }
                      >
                        <span>Comportamenti diseducativi</span>
                        {Dots(review.bad_behaviours)}
                      </li>
                      <li
                        className={
                          "list-group-item d-flex justify-content-between align-items-center"
                        }
                      >
                        <span>Possibilità discussione</span>
                        {Dots(review.discussion)}
                      </li>
                    </ul>
                  </Col>
                </Row>
              );
            })}
          </Modal.Body>

          {this.props.showTagSearch &&
            movie.tags.map((tag) => {
              return (
                <NavLink
                  to={
                    "/search?tags=" +
                    tag +
                    "&year=&score=&title=&director=&actor="
                  }
                  className={"m-1"}
                >
                  Altri film con genere "{tag}"
                </NavLink>
              );
            })}
        </Modal>
      </Suspense>
    );
  }
}
