import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import MovieCard from "../components/MovieCardV2";
import { useIsInViewport } from "../utils/hooks";
import { fetchMoviesByGenre } from "../api/movies";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export default function GenreRow(props) {
  const { moviesByGenre, genre, index, forceLoad } = props;
  const [loadedMovies, setLoadedMovies] = useState([]);
  const myself = useRef(null);
  const isInViewport = useIsInViewport(myself);

  const loadMoviesByGenre = async (genre) => {
    const movies = await fetchMoviesByGenre(genre);
    setLoadedMovies(movies);
  };

  useEffect(() => {
    if (moviesByGenre) {
      setLoadedMovies(moviesByGenre);
    } else if (forceLoad) {
      console.log(
        `Genre ${genre} is likely not in viewport! But loading anyway!`,
      );
      loadMoviesByGenre(genre);
    }
  }, []);

  useEffect(() => {
    if (isInViewport && (loadedMovies ?? []).length === 0) {
      console.log(`Genre ${genre} is in viewport! Now loading`);
      loadMoviesByGenre(genre);
    }
  }, [isInViewport]);

  return (
    <Row ref={myself} key={index}>
      <h3>{genre.capitalize()}</h3>
      <div className="d-flex flex-row overflow-auto">
        {(loadedMovies ?? []).map((m, j) => {
          return (
            <MovieCard
              movie={m}
              key={index * (loadedMovies ?? []).length + j + 1}
              showTagSearch={true}
            />
          );
        })}
      </div>
    </Row>
  );
}
