import Circle from "@mui/icons-material/Circle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import MDEditor from "@uiw/react-md-editor";
export default function Dots(dotsCount) {
  const dc = parseInt(dotsCount);
  let hasHalf = parseFloat(dotsCount) - dc > 0;

  try {
    return (
      <span>
        {Array(dc)
          .fill(0)
          .map((_, i) => (
            <span key={i}>
              <Circle fontSize={"inherit"} />
            </span>
          ))}
        {hasHalf && (
          <span>
            <CircleTwoToneIcon fontSize={"inherit"} />
          </span>
        )}
        {Array(4 - dc - hasHalf)
          .fill(0)
          .map((_, i) => (
            <span key={i}>
              <CircleOutlined fontSize={"inherit"} />
            </span>
          ))}
      </span>
    );
  } catch (e) {
    return <span></span>;
  }
}
