import React from "react";
import {Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap"
import * as PropTypes from 'prop-types'
import ModifiableReviewForm from "./ModifiableReviewForm"

export default class ModifiableMovieForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            movie: props.movie,
            reviews: props.reviews,
            reviewsReady: false,
            validation: [],
        }
    }

    static emptyMovie = () => {
        return {
            title: "",
            original_title: "",
            directors: [],
            actors: [],
            year: new Date().getFullYear(),
            image_src: "",
            duration: null,
            tags: [],
            state: "",
            starred: false
        }
    }

    _renderTooltipWithMessage = (message) => {
        return (props) =>
            (
                <Tooltip id="button-tooltip" {...props}>
                    {message}
                </Tooltip>
            )
    }

    _addNewReviewTemplate = () => {
        this.setState({
            reviews: [...this.state.reviews, ModifiableReviewForm.emptyReview()],
            validation: [...this.state.validation, {}]
        })
    }

    _removeReviewAtIndex = (i) => {
        return () => {
            let currentReviews = [...this.state.reviews].filter((v, idx) => i !== idx)
            this.setState({reviews: currentReviews})
        }
    }

    _setMovieProp = (movieProp) => {
        return ({target}) => {
            const newValue = {...this.state.movie}
            newValue[movieProp] = target.value
            this.setState({movie: {...newValue}})
        }
    }

    _validateReviews = () => {
        const {reviews} = this.state
        const invalidReviews = []

        reviews.forEach((review, i) => {
            invalidReviews.push({valid: true})
            Object.entries(review).forEach(([key, value]) => {
                if (value === 0 || key === "age") return
                if (value == "" || value == null) {
                    invalidReviews[i][key] = true
                    invalidReviews[i]["valid"] = false
                }
            })
        })
        this.setState({validation: invalidReviews})
        return reviews.every((r, i) => invalidReviews[i].valid)
    }

    _create = () => {
        if (!this._validateReviews()) return
        this.props.createReview(this.state)
    }

    _update = () => {
        if (!this._validateReviews()) return
        this.props.updateReview(this.state)
    }

    _setReviewProps = (index, review) => {
        let reviews = this.state.reviews
        reviews[index] = review
        this.setState({reviews})
    }

    _syncPropsAndState = () => {
        this.setState({movie: this.props.movie, reviews: this.props.reviews, reviewsReady: true}, () => {
            if (this._mrf) this._mrf.forceStatePropsSync()
        })
    }

    render() {
        const TAGS = this.props.tags ?? []
        return (
            <Modal onHide={this.props.onHide} size={"lg"} backdrop={"static"} show={this.props.shouldOpen} onShow={this._syncPropsAndState}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.movie.title != "" ? this.props.movie.title : "Nuova Recensione"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        // BEGIN MOVIE PROPERTIES
                    }
                    <Form.Group as={Row}>
                        <Form.Label column md="4">
                            Titolo
                        </Form.Label>
                        <Col md="8">
                            <Form.Control isInvalid={this.props.validation?.title} type={"text"} placeholder="Titolo"
                                          defaultValue={this.props.movie.title}
                                          onChange={this._setMovieProp("title")}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className={"mt-1"}>
                        <Form.Label column md="4">
                            Titolo originale
                        </Form.Label>
                        <Col md="8">
                            <Form.Control isInvalid={this.props.validation?.original_title} type={"text"}
                                          placeholder="Titolo originale"
                                          defaultValue={this.props.movie.original_title}
                                          onChange={this._setMovieProp("original_title")}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className={"mt-1"}>
                        <Form.Label column md="4">
                            Regista
                        </Form.Label>
                        <OverlayTrigger
                            placement="right"
                            delay={{show: 0, hide: 400}}
                            overlay={this._renderTooltipWithMessage("Separati da virgola")}
                        >
                            <Col md="8">
                                <Form.Control isInvalid={this.props.validation?.directors} type={"text"}
                                              placeholder="Registi"
                                              defaultValue={this.props.movie.directors.join(",")}
                                              onChange={({target}) => this.setState({
                                                  movie: {
                                                      ...this.state.movie,
                                                      directors: target.value.split(",")
                                                  }
                                              })}/>
                            </Col>
                        </OverlayTrigger>
                    </Form.Group>

                    <Form.Group as={Row} className={"mt-1"}>
                        <Form.Label column md="4">
                            Attori
                        </Form.Label>
                        <OverlayTrigger
                            placement="right"
                            delay={{show: 0, hide: 400}}
                            overlay={this._renderTooltipWithMessage("Separati da virgola")}
                        >
                            <Col md="8">
                                <Form.Control type={"text"}
                                              isInvalid={this.props.validation?.actors}
                                              placeholder="Attori"
                                              defaultValue={this.props.movie.actors.join(",")}
                                              onChange={({target}) => this.setState({
                                                  movie: {
                                                      ...this.state.movie,
                                                      actors: target.value.split(",")
                                                  }
                                              })}/>
                            </Col>
                        </OverlayTrigger>
                    </Form.Group>

                    <Form.Group as={Row} className={"mt-1"}>
                        <Form.Label column md="4">
                            Genere
                        </Form.Label>
                        <Col md="8">
                            <Form.Select
                                multiple
                                isInvalid={this.props.validation?.tags}
                                defaultValue={this.props.movie.tags ?? ["avventura"]}
                                onChange={(e) => this.setState({
                                    movie: {
                                        ...this.state.movie,
                                        tags: [].slice.call(e.target.selectedOptions).map(item => item.value)
                                    }
                                })}
                            >
                                {TAGS.sort((t1, t2) => {
                                    const t1Lower = t1.toLowerCase()
                                    const t2Lower = t2.toLowerCase()
                                    if(t1Lower >= t2Lower) return 1
                                    return -1
                                }).map((v) => {
                                    return <option value={v}>{v}</option>
                                })}
                            </Form.Select>
                        </Col>

                    </Form.Group>

                    <Form.Group as={Row} className={"mt-1"}>
                        <Form.Label column md="4">
                            Anno
                        </Form.Label>
                        <Col md="8">
                            <Form.Select
                                isInvalid={this.props.validation?.year}
                                onChange={({target}) => this.setState({
                                    movie: {
                                        ...this.state.movie,
                                        year: target.selectedOptions[0].value
                                    }
                                })}
                                defaultValue={this.state.movie.year ?? 2022}
                            >
                                <option disabled > Anno di uscita</option>
                                {Array((new Date().getFullYear()) - 1895).fill(0).map((v, i) => i + 1896).sort((a, b) => b - a).map((v) => {
                                    return <option value={v}>{v}</option>
                                })}
                            </Form.Select>
                            {/*<Form.Control type={"number"} placeholder="Anno" defaultValue={this.props.movie.year}
                                           onChange={this._setMovieProp("year")}/>*/}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className={"mt-1"}>
                        <Form.Label column md="4">
                            Locandina
                        </Form.Label>
                        <Col md="8">
                            <Form.Control
                                isInvalid={this.props.validation?.image_src}
                                type={"file"}
                                placeholder="Locandina"
                                accept={"image/*"}
                                disabled={this.props.movie.image_src !== ""}
                                onChange={({target}) => this.setState({
                                    movie: {
                                        ...this.state.movie,
                                        image_src: target.files[0]
                                    }
                                })}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className={"mt-1"}>
                        <Form.Label column md="4">
                            Durata
                        </Form.Label>
                        <Col md="8">
                            <Form.Control type={"number"}
                                          isInvalid={this.props.validation?.duration}
                                          placeholder="Durata" defaultValue={this.props.movie.duration}
                                          onChange={this._setMovieProp("duration")}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className={"mt-1"}>
                        <Form.Label column md="4">
                            Paese
                        </Form.Label>

                        <Col md="8">
                            <Form.Control type={"text"} placeholder="Paese" isInvalid={this.props.validation?.state}
                                          defaultValue={this.props.movie.state}
                                          onChange={this._setMovieProp("state")}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className={"mt-1"}>
                        <Form.Label column md="4">
                            In evidenza
                        </Form.Label>

                        <Col md="8" style={{display: "flex", justifyContent: "flex-end"}}>
                            <Form.Check type={"switch"} isInvalid={this.props.validation?.starred}
                                        checked={this.state.movie.starred}
                                        onChange={({target}) => this.setState({
                                            movie: {
                                                ...this.state.movie,
                                                starred: target.checked
                                            }
                                        })}/>
                        </Col>
                    </Form.Group>

                    {
                        // END MOVIE PROPERTIES
                    }
                    <hr/>
                    {
                        // BEGIN MOVIE REVIEWS
                    }

                    {
                        this.state.reviewsReady && this.state.reviews.map((review, idx) => {
                            return (
                                <ModifiableReviewForm
                                    ref={mrf => this._mrf = mrf}
                                    review={review}
                                    reviewIndex={idx}
                                    deleteReview={this._removeReviewAtIndex(idx)}
                                    setReviewProps={this._setReviewProps}
                                    validation={this.state.validation}
                                />

                            )
                        })
                    }

                    {
                        // END MOVIE REVIEWS
                    }

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={this._addNewReviewTemplate}>+</Button>

                    <Col style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button variant={"primary"}
                                onClick={this.props.type == "create" ? () => this._create() : () => this._update()}>
                            {this.props.type == "create" ? "Salva" : "Aggiorna"}
                        </Button>
                    </Col>

                </Modal.Footer>

            </Modal>
        )
    }

}

ModifiableMovieForm.propTypes = {
    createReview: PropTypes.func,
    updateReview: PropTypes.func,
    type: PropTypes.string,
    onHide: PropTypes.func,
    shouldOpen: PropTypes.bool,
    movie: PropTypes.object,
    reviews: PropTypes.array,
}
