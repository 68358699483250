import React from "react";
import "bootstrap/dist/css/bootstrap.css";

import { Container, Row } from "react-bootstrap";

import WebsiteNavbar from "../components/WebsiteNavbar";
import NavbarTitlebox from "../components/NavbarTitlebox";
import HeroHeader from "../components/HeroHeader";
import { API_URL } from "../utils/config";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export default class Presentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authors: [],
    };
  }

  async componentDidMount() {
    let authors = await fetch(API_URL + "inputs/authors");
    authors = await authors.json();
    this.setState({ authors });
    console.log(authors);
  }

  render() {
    return (
      <>
        <HeroHeader allowRouteToHome={true}>
          <NavbarTitlebox />
        </HeroHeader>
        <WebsiteNavbar />
        <Container>
          <Row className={"mt-5"}>
            <h3>Il Progetto</h3>
            <span style={{ "white-space": "pre-line" }}>
              Questo sito è indirizzato a un pubblico familiare per fornire un
              primo orientamento di massima quando si deve scegliere un film al
              volo. L’inevitabile soggettività dei giudizi (e soprattutto dei
              voti!) vuole essere compensata dalla garanzia di applicazione di
              criteri che consentano di valutare, quanto meno, la "visibilità"
              del film a seconda dell’età e della maturità dello spettatore.
              <br />
              Caratteri distintivi del sito sono:
              <br />
              <ul>
                <li>
                  schede brevi e intuitive (più che recensioni vere e proprie)
                </li>
                <li>voti in decimi;</li>
                <li>
                  dettaglio grafico di situazioni che potrebbero rendere
                  problematica la visione e/o imbarazzante la serata (sesso,
                  violenza, temi da adulti)
                </li>
              </ul>
              Il sito è indirizzato prioritariamente a:
              <br />
              <ul>
                <li>famiglie</li>
                <li>giovani</li>
                <li>formatori</li>
                <li>
                  organizzatori di serate di cinema o cineforum, più o meno
                  informali
                </li>
                <li>appassionati di cinema in genere</li>
              </ul>
              <br />
              Astenersi estimatori del cinema orientale, iraniano, indiano,
              africano e, in genere, dei prodotti "da festival".
              <br />I redattori delle schede recensiscono un film che sono
              andati a vedere (cosa che non sempre accade altrove: basti vedere
              le solenni ronfate dei critici ufficiali ai festival
              internazionali…), ma non vanno a vedere un film solo per
              recensirlo! E quindi non troverete mai le schede di pellicole che
              a loro non interessavano neppure un po’.
            </span>
          </Row>

          <Row className={"mt-5"}>
            <h3>Gli Autori</h3>
            {this.state.authors.map((author) => {
              return (
                <>
                  <h5>{author.name}</h5>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: author.description.replace(/\/n/g, "<br/>"),
                    }}
                  ></span>
                  <br />
                  <b>Citazioni preferite: </b>
                  <ul>
                    {author.citations.split("+++").map((citation) => {
                      return <li>{citation}</li>;
                    })}
                  </ul>
                </>
              );
            })}
          </Row>
        </Container>
      </>
    );
  }
}
