import {TailSpin} from "react-loader-spinner";
import React from "react";


class Spinner extends React.Component {
    render() {
        return (
            <div style={{position: "absolute", top: "50%", left: "47%"}}>
                <TailSpin
                    height="80"
                    width="80"
                    color={"blue"}
                    visible={this.props.isLoading}
                />
            </div>
        )
    }
}

export default Spinner
